import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import ReduxThunk from "redux-thunk"
import Reducers from "./reducers"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { PersistGate } from "redux-persist/integration/react"
import { composeWithDevTools } from "redux-devtools-extension"

import { BallTriangle as Loading } from "../components/Common/loaders"

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, Reducers)
const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)
const store = createStoreWithMiddleware(persistedReducer, composeWithDevTools())
const persistor = persistStore(store)

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate
      loading={
        <div id="full-screen">
          <Loading color={`#ff0000`} size={`100`} />
        </div>
      }
      persistor={persistor}
    >
      {element}
    </PersistGate>
  </Provider>
)
