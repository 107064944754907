// LOADING
export const LOADING = "loading"
export const LOADING_CLEAR = "loading_clear"
// AUTH
export const AUTH_USER = "auth_user"
export const UNAUTH_USER = "unauth_user"
export const AUTH_ERROR = "auth_error"
export const FORGOT_PASSWORD = "forgot_password"
export const RESET_PASSWORD = "reset_password"
// REGISTER
export const REGISTER_ERROR = "register_error"
// BRACKET
export const UPDATE_BRACKET_STATE = "update_bracket_state"
export const BRACKET_SUCCESS = "bracket_success"
export const BRACKET_ERROR = "bracket_error"
export const UPDATE_BRACKET_ERROR = "update_bracket_error"
export const UPDATE_BRACKET_SUCCESS = "update_bracket_success"
// CSV
export const CSV_SUCCESS = "csv_success"
export const CSV_ERROR = "csv_error"
