import {
  LOADING,
  LOADING_CLEAR,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_ERROR,
  UPDATE_BRACKET_STATE,
  UPDATE_BRACKET_SUCCESS,
  UPDATE_BRACKET_ERROR,
  BRACKET_SUCCESS,
  BRACKET_ERROR,
  CSV_SUCCESS,
  CSV_ERROR,
} from "../types"

export default function(state = { authenticated: false }, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case LOADING_CLEAR:
      return {
        ...state,
        loading: false,
      }
    case AUTH_USER:
      return {
        ...state,
        user: action.payload.user,
        authenticated: true,
        authError: "",
        bracket: action.payload.bracket,
        loading: false,
        updateSuccess: "",
        updateError: "",
        bracketSuccess: "",
        bracketError: "",
      }
    case UNAUTH_USER:
      return { ...state, authenticated: false, authError: "", loading: false }
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        authError: action.payload,
        loading: false,
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: false,
        forgotpassword: action.payload,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        resetSuccess: action.payload,
      }
    case REGISTER_ERROR:
      return {
        ...state,
        authenticated: false,
        authError: action.payload,
        loading: false,
      }
    case UPDATE_BRACKET_STATE:
      return {
        ...state,
        bracket: action.payload,
        loading: false,
      }
    case UPDATE_BRACKET_SUCCESS:
      return {
        ...state,
        updateSuccess: action.payload,
        updateError: "",
        loading: false,
      }
    case UPDATE_BRACKET_ERROR:
      return {
        ...state,
        updateError: action.payload,
        updateSuccess: "",
        loading: false,
      }
    case BRACKET_SUCCESS:
      return {
        ...state,
        bracket: action.payload.bracket,
        bracketSuccess: action.payload.message,
        bracketError: "",
        loading: false,
      }
    case BRACKET_ERROR:
      return {
        ...state,
        bracketSucces: "",
        bracketError: action.payload,
        loading: false,
      }
    case CSV_SUCCESS:
      return {
        ...state,
        csvSuccess: action.payload,
        csvError: "",
        loading: false,
      }
    case CSV_ERROR:
      return {
        ...state,
        csvSuccess: "",
        csvError: action.payload,
        loading: false,
      }
    default:
      return { ...state }
  }
}
